* {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  height: auto;
  width: auto;
}
